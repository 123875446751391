<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">사업장</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="search.bizCode"
                    :dataSource="businessPlaceDropdownListDatasource"
                    :allowFiltering="false"
                    :fields="{ text: 'text', value: 'value' }"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">사용 일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="search.dateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">사용자</div>
            <ul class="content">
              <li class="item">
                <input-text
                    v-model="search.userName"
                    placeholder="내용을 입력하세요."
                />
              </li>
            </ul>
          </li>
          <search-menu-list ref="childComp"/>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="fetchProgramButtonUseHistory"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <report-view
          ref="reportViewComponent"
          :isPopup="true"
      />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">프로그램 버튼 사용이력</div>
              <div class="header-caption">[{{content.programButtonUseHist.length}}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="onPrintClick"
                      :is-shortcut-button="true"
                  >인쇄
                  </erp-button>

                </li>
                <li class="print">
                  <erp-button
                      :disabled="content.programButtonUseHist.length === 0"
                      button-div="FILE"
                      @click.native="onExcelClick"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  :allowEditing="false"
                  :allowExcelExport="true"
                  :allowPaging="true"
                  :allowResizing="true"
                  :provides="grid"
                  :pageSettings="pageSettings"
                  :columns="columns"
                  :data-source="content.programButtonUseHist"

              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import ReportView from "../../components/common/report/ReportView.vue";
import ejsGridWrapper from "../../components/common/EjsGridWrapper.vue";
import InputText from "../../components/common/text/InputText.vue";
import InputDateRange from "../../components/common/datetime/InputDateRange";
import SearchMenuList from "./common/SearchMenuList.vue";
import commonMixin from "../../views/layout/mixin/commonMixin";
import { getBizCodesObject } from "@/utils/pageKeyUtil";
import GolfErpAPI from "../../api/v2/GolfErpAPI";
import {ExcelExport, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "ProgramButtonUseHist",
  mixins: [commonMixin],
  props: {

  },
  components: {
    ReportView,
    ejsGridWrapper,
    InputDateRange,
    InputText,
    SearchMenuList,
    ErpButton,
  },
  created() {
  },
  mounted() {
    document.addEventListener('click', this.childEvent);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.childEvent);
  },
  computed: {
    businessPlaceDropdownListDatasource() {
      const businessPlaceDropdownListDatasource = (
          getBizCodesObject() || []
      ).map(({ bizName, bizCode }) => ({
        text: bizName,
        value: bizCode,
      }));

      businessPlaceDropdownListDatasource.unshift({ text: "ALL", value: "" });
      return businessPlaceDropdownListDatasource;
    }
  },
  data () {
    return{
      ipAddress: '',
      isAddPrintPopupOpen: false,
      search: {
        bizCode: '',
        userName: '',
        menuName: '',
        dateRange: {
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
      },
      content: {
        programButtonUseHist: []
      },
      grid: [
        Resize,
        Page,
        ExcelExport,
        ForeignKey,
      ],
      columns: [
        {
          field: 'logId',
          visible: false,
          type: 'number',
        },
        {
          field: 'bizName',
          headerText: '사업장',
          textAlign: 'center',
          width: 100,
          type: 'string',
        },
        {
          field: 'userName',
          headerText: '사용자명',
          textAlign: 'center',
          width: 50,
          type: 'string',
        },
        {
          field: 'useDate',
          headerText: '사용일시',
          textAlign: 'center',
          width: 50,
          type: 'string',
        },
        {
          field: 'menuName',
          headerText: '프로그램명',
          textAlign: 'center',
          width: 100,
          type: 'string',
        },
        {
          field: 'pgId',
          headerText: '프로그램ID',
          width: 170,
          type: 'string',
        },
        {
          field: 'urlPttrn',
          headerText: 'URL',
          width: 100,
          type: 'string',
        },
        {
          field: 'authName',
          headerText: '권한명',
          width: 100,
          textAlign: 'center',
          type: 'string',
        },
        {
          field: 'authId',
          headerText: '권한ID',
          width: 80,
          type: 'string',
        },
        {
          field: 'buttonUseDivName',
          headerText: '구분',
          width: 40,
          textAlign: 'center',
          type: 'string',
        },
        {
          field: 'connectIp',
          headerText: '사용자 IP',
          width: 100,
          textAlign: 'center',
          type: 'string',
        },
      ],
      pageSettings: {pageSize: 50},
      buttonDiv: [
        {key: '1', text: '조회'},
        {key: '2', text: '저장'},
        {key: '3', text: '삭제'},
        {key: '4', text: '인쇄'},
        {key: '5', text: 'Excel'},
      ]
    };
  },
  methods: {
    async fetchProgramButtonUseHistory() {
      let params = {
        bizCode: this.search.bizCode,
        fromDate: this.search.dateRange.from,
        toDate: this.search.dateRange.to,
        userName: this.search.userName,
        menuName: this.$refs.childComp.menuName
      };

      await GolfErpAPI.getProgramButtonUseHist(params).then(res => {
        if (res) {
          res.forEach(f => {
            f.buttonUseDivName = this.buttonDiv.find(o => o.key === f.buttonUseDiv).text;
            f.useDate = moment(f.useDate).format('YYYY-MM-DD');
          });

          this.content.programButtonUseHist = res;
        }
      });
    },
    onPrintClick() {
      const result = this.$refs.grid.getGridBatchData();

      if (result.length < 1) {
        return this.errorToast(this.$t('report.popupMessage.noData'));
      }

      const searchOptionsList = [
        {
          key: '사업장',
          value : this.businessPlaceDropdownListDatasource.filter((i) => i.value === this.search.bizCode)[0].text
        },
        {
          key: '조회일자',
          value: `${this.search.dateRange.from} ~ ${this.search.dateRange.to}`
        },
        {
          key: '사용자',
          value: this.search.userName
        },
        {
          key: '프로그램명',
          value: this.$refs.childComp.menuName
        }
      ];

      const searchOptions = searchOptionsList
          .filter(item => !!item.value)
          .map(item => item.key + ': ' + item.value)
          .join(', ');

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.search.userName,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onExcelClick() {
      this.$refs.grid.excelExport();
    },
    childEvent() {
      if (this.$refs.childComp.menuSearchList.length > 0) {
        this.$refs.childComp.refresh();
      }
    }
  },

};
</script>

<style scoped>

</style>